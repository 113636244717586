// Angular modules
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InputModule as UiKitInputModule, PipesModule } from '@connatix/ui-kit';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskDirective } from 'ngx-mask';
import { CoreDirectivesModule } from '../core/directives/core-directives.module';
import { FormControlsModule } from '../core/ui-kit/form-controls/form-controls.module';
import { CnxMaterialModule } from './material.module';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CnxMaterialModule,
  OverlayModule,
  RouterModule,
  InfiniteScrollModule,
  FormControlsModule,
  CoreDirectivesModule,
  UiKitInputModule,
  NgxMaskDirective
];

const declarations = [];
const providers = [
  CurrencyPipe
];

@NgModule({
  imports: [...modules, RouterModule, PipesModule],
  declarations: [...declarations],
  exports: [...modules, ...declarations],
  providers: [...providers]
})
export class GlobalModule {
}
