import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PermissionsEnum } from '@connatix/graphql-layer';
import { deepClone } from '@connatix/ui-kit';
import { AccountService, Globals, PlayerMediumEnum, UserContextService, WordpressService } from '@connatix/ckit';
import {
  EMBED_EMPTY, GrabCodeHistoryService, IGrabCodeApiService, IGrabCodeByPlayerComponent, IGrabCodeByPlayerComponentState,
  IGrabInAppCodeComponentState, PlayerSelectedKeyValue, VideoOptionsFormService, VideoPreviewDialogData,
  VideoPreviewSliderComponent as CkitVideoPreviewSliderComponent
} from '@connatix/ckit/library';

@Component({
  selector: 'ckit-video-preview-slider',
  templateUrl: './video-preview-slider.component.html',
  styleUrls: ['./video-preview-slider.component.scss']
})
export class VideoPreviewSliderComponent extends CkitVideoPreviewSliderComponent
  implements OnDestroy, IGrabCodeByPlayerComponentState, IGrabInAppCodeComponentState, IGrabCodeByPlayerComponent {

  constructor(
    public readonly dialogRef: MatDialogRef<CkitVideoPreviewSliderComponent>,
    public readonly wordpressService: WordpressService,
    public readonly historyService: GrabCodeHistoryService,
    public readonly globals: Globals,
    protected readonly userContextService: UserContextService,
    protected readonly videoOptionsFormService: VideoOptionsFormService,
    protected readonly accountService: AccountService,
    protected readonly router: Router,
    @Inject('IGrabCodeApiService') protected readonly api: IGrabCodeApiService,
    @Inject(MAT_DIALOG_DATA) public data: VideoPreviewDialogData
  ) {
    super(dialogRef, wordpressService, historyService, globals, userContextService, videoOptionsFormService,
      accountService, router, api, data);
    this.path = this.router.url;
    const storedPlayerId = this.historyService.getPlayerIdFromHistory(this.path);
    if (storedPlayerId) {
      this.showSkeletonLoader = true;
      this.playerSelected.value = storedPlayerId;
      this.loadEmbedCode(this.playerSelected);
    }

    const accountId = this.userContextService.account.id === '' ? this.data.accountId : this.userContextService.account.id;
    this.showSideDrawerDetails = this.path.includes('library');
    if (this.showSideDrawerDetails) {
      this.accountService.getAccounts([accountId]).subscribe(account => {
        this.extraDataForm = this.videoOptionsFormService.createForm({
          title: this.data.title,
          description: this.data.description,
          clickUrl: this.data.clickUrl,
          keywords: this.data.keywords,
          customFields: this.data.customFields
        }, account[0].customFields);
        this.extraDataCopy = deepClone(this.extraDataForm.value);
      });
    }
  }

  public loadEmbedCode(
    playerSelected: PlayerSelectedKeyValue,
    appSelected?: string
  ): void {
    this.extensionMessageData = this.getExtensionMessageData();
    /** update chrome storage history for embed code by sending a message to parent window with postMessage  */
    this.historyService.updateHistory(this.extensionMessageData.playerId, this.path);
    top.postMessage({ embedCodeHistory: this.historyService.embedCodeHistory }, '*');

    if (playerSelected.value === null) {
      this.setGrabCodeDefaults();
    } else {
      switch (playerSelected.key) {
        case PlayerMediumEnum.WEB:
          if (!this.showSkeletonLoader) {
            this.globals.openLoader();
          }
          // fetch from server
          this.api
            .getMediaEmbedCode(
              this.mode,
              playerSelected.value,
              this.data.mediaId,
              undefined,
              undefined,
              undefined,
              undefined,
              this.getExtraInfo()
            )
            .pipe(finalize(() => {
              this.showSkeletonLoader = false;
              this.globals.closeLoader();
            }))
            .subscribe(({ embedCodeWeb, embedCodeAmp, embedCodeGam }) => {
              this.embedCodeModel.embedCodeAmp = EMBED_EMPTY;
              this.embedCodeModel.embedCodeWeb = EMBED_EMPTY;
              this.embedCodeModel.embedCodeGam = EMBED_EMPTY;
              this.setEmbedCodeModel(embedCodeWeb, embedCodeAmp, embedCodeGam);
            });
          break;
        case PlayerMediumEnum.APP:
          this.setEmbedCodeInAppModel(playerSelected, appSelected);
          break;
        default:
          return;
      }
    }
  }

  /**
   * Hides 'Send Embed Code to CMS' button
   * @returns true if has Chrome Plugin Embed Event permission and is in extension mode
   */
  public showSendEmbedCodetoCMS(): boolean {
    const hasChromePluginEmbedEvent = this.userContextService.permissions.includes(PermissionsEnum.ChromePluginEmbedEvent);
    return hasChromePluginEmbedEvent && !this.wordpressService.isWordpress;
  }
}
