import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmbedCodeType, PermissionsEnum } from '@connatix/graphql-layer';
import { deepClone } from '@connatix/ui-kit';
import { AccountService, Globals, UserContextService, WordpressService } from '@connatix/ckit';
import {
  GrabCodeFilteredSliderComponent as CkitGrabCodeFilteredSliderComponent, GrabCodeHistoryService,
  GrabCodeSliderDialogData, IGrabCodeApiService, IGrabCodeByPlayerComponent, IGrabCodeByPlayerComponentState,
  IGrabInAppCodeComponentState, PlayerOptionsFormService, PlayerSelectedKeyValue, setGrabCodeDefaults,
  VideoOptionsFormService
} from '@connatix/ckit/library';

@Component({
  selector: 'ckit-grab-code-filtered-slider',
  templateUrl: './grab-code-slider.component.html',
  styleUrls: ['./grab-code-slider.component.scss']
})
export class GrabCodeFilteredSliderComponent extends CkitGrabCodeFilteredSliderComponent
  implements OnDestroy, IGrabCodeByPlayerComponentState, IGrabInAppCodeComponentState, IGrabCodeByPlayerComponent {

  constructor(
    public readonly wordpressService: WordpressService,
    public readonly historyService: GrabCodeHistoryService,
    public readonly globals: Globals,
    protected readonly userContext: UserContextService,
    protected readonly videoOptionsFormService: VideoOptionsFormService,
    protected readonly playerOptionsFormService: PlayerOptionsFormService,
    protected readonly dialogRef: MatDialogRef<CkitGrabCodeFilteredSliderComponent>,
    protected readonly router: Router,
    protected readonly accountService: AccountService,
    @Inject('IGrabCodeApiService') protected readonly api: IGrabCodeApiService,
    @Inject(MAT_DIALOG_DATA) protected readonly data: GrabCodeSliderDialogData
  ) {
    super(wordpressService, historyService, globals, userContext, videoOptionsFormService, playerOptionsFormService,
      dialogRef, router, accountService, api, data);
  }

  public loadEmbedCode(playerSelected: PlayerSelectedKeyValue, appSelected?: string): void {
    /** stop the execution if user is allowed to set lead videos and these two values are equal */
    if (this.allowLeadMedia && this.isDuplicatedLeadVideos()) {
      // clear head and body script
      setGrabCodeDefaults(this.embedCodeModel, this.embedCodeInApp, this.embedCodeSaexPlayer);
      return;
    }

    /** get data that needs to be passed to chrome extension */
    this.extensionMessageData = this.getMessageData();

    /** update chrome storage history for embed code by sending a message to parent window with postMessage  */
    this.historyService.updateHistory(this.extensionMessageData.playerId, this.path);
    top.postMessage({ embedCodeHistory: this.historyService.embedCodeHistory }, '*');

    if (playerSelected.value === null) {
      setGrabCodeDefaults(this.embedCodeModel, this.embedCodeInApp, this.embedCodeSaexPlayer);
      this.mode = this.mode || EmbedCodeType[EmbedCodeType.WEB];
    } else {
      if (this.isSaexPlayer) {
        this.setEmbedCodeSaexPlayerModel(playerSelected);
      } else {
        this.loadEmbedCodeNonSaexPlayer(playerSelected, appSelected);
      }
    }
  }

  /**
   * Hides 'Send Embed Code to CMS' button
   * @returns true if has Chrome Plugin Embed Event permission and is in extension mode
   */
  public showSendEmbedCodetoCMS(): boolean {
    const hasChromePluginEmbedEvent = this.userContext.permissions.includes(PermissionsEnum.ChromePluginEmbedEvent);
    return hasChromePluginEmbedEvent && !this.wordpressService.isWordpress;
  }

  protected initPageDetails(): void {
    const accountId = this.accountId === '' ? this.data.accountId : this.accountId;
    this.showSideDrawerDetails = (this.isLibraryPage || this.isPlayersPage);
    if (this.showSideDrawerDetails) {
      this.detailedViewTitle = this.isLibraryPage ? 'Customize Embedded Video Instance' : 'Customize Embedded Player Instance';
      if (this.isLibraryPage) {
        this.accountService.getAccounts([accountId]).subscribe(account => {
          this.extraDataForm = this.videoOptionsFormService.createForm(
            {
              title: this.data.subtitle,
              description: this.data.description,
              clickUrl: this.data.clickUrl,
              keywords: this.data.keywords,
              customFields: this.data.customFields
            }, account[0].customFields);
          this.extraDataCopy = deepClone(this.extraDataForm.value);
        });
      }
      if (this.isPlayersPage) {
        this.extraDataForm = this.playerOptionsFormService.createForm();
      }
    }
  }

  protected setPlayerAccountId(): void {
    if (!this.accountId) {
      this.wordpressService.getPlayerAccount(this.data.playerId)
        .subscribe(accountId => this.playerAccountId = accountId);
    } else {
      this.playerAccountId = this.accountId;
    }
  }
}
