import { Inject, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CONFIG_TOKEN, ENVIRONMENT_TOKEN, IConfig, IEnvironment } from '@connatix/ui-kit';
import { ConfigModule as CkitConfigModule, Globals, PlatformUrlEnum } from '@connatix/ckit';

@NgModule({})
export class ConfigModule extends CkitConfigModule {

  constructor(
    protected readonly globals: Globals,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(ENVIRONMENT_TOKEN) protected readonly environment: IEnvironment,
    @Inject(CONFIG_TOKEN) protected readonly config: IConfig
  ) {
    super(globals, document, environment, config);
    this.globals.isEmbedded = true;
    this.globals.setPlatform(PlatformUrlEnum.ELEMENTS);
  }
}
