// This file can be replaced during build by using the `fileReplacements` array.
// `ng build -c {env}` replaces `environment.ts` with `environment.{env}.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  development: false,
  domain: '',
  config: null,
  envId: ''
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
