import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { MainMenuSections, MenuItemRequiresEnum } from './menu-items/menu.items';
import { SidenavBaseComponent } from '@connatix/ckit/sidenav';
import {
  FeatureToggleService, Globals, LayoutService, MenuItem, shouldRedirectToHub, UserContextService
} from '@connatix/ckit';
import { AccountPlatformEnum, UserTypeEnum } from '@connatix/graphql-layer';

/**
 * A component that renders a list of menu-items for navigation
 */
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent extends SidenavBaseComponent implements OnInit, DoCheck, OnDestroy {
  constructor(
    protected readonly layoutService: LayoutService,
    protected readonly userContextService: UserContextService,
    protected readonly globals: Globals,
    public readonly featureToggle: FeatureToggleService
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    this.isAppSwitcherVisible = false;
    this.menuSections = MainMenuSections;

    this.subscriptions$.push(
      this.layoutService.sidenavCollapsed.subscribe(v => {
        this.isCollapsed = v;
      }),
      this.userContextService.permissions$.subscribe(permissions => this.renderMenu(permissions))
    );
  }

  ngDoCheck(): void {
    super.ngDoCheck();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Render visible menu items
   * @param userPermissions Permission list
   */
  private renderMenu(userPermissions: null | number[]): void {
    const permissions = userPermissions || [];
    const { hasAccessToSingleCustomer, userType } = this.userContextService.userProfile;
    const allowExternal = shouldRedirectToHub(userType) ? false :
      hasAccessToSingleCustomer ||
      userType === UserTypeEnum.Advertiser ||
      userType === UserTypeEnum.SinglePlatformNetwork;

    this.menuSections.forEach(section => {
      section.Items.forEach(item => {
        item.Hidden = this.isHidden(item, permissions);
        if (!item.Hidden) {
          item.Hidden = item.Requires === MenuItemRequiresEnum.EXTERNAL ?
            !allowExternal ||
            this.hasAccessTo([AccountPlatformEnum.PLAYSPACE]) ||
            (item.Platforms && !this.hasAccessTo(item.Platforms)) :
            item.Hidden;
        }
        this.setupExtensionMenu(item);
      });
    });
  }

  private setupExtensionMenu(item: MenuItem): void {
    if (!['Library', 'Playlists', 'Content Suite', 'Video Players'].some(name => name === item.Name)) {
      item.Hidden = true;
    }
  }

  private isHidden(item: MenuItem, permissions: number[]): boolean {
    return item.permissions ? !item.permissions.some(v => permissions.includes(v)) : true;
  }

  private hasAccessTo(platforms: AccountPlatformEnum[]): boolean {
    return this.userContextService
      .userProfile
      .accessiblePlatforms
      .some(accessiblePlatform => platforms.includes(accessiblePlatform.platform));
  }
}
