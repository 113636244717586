import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';

import {
  CnxCardsModule as UiKitCardsModule, CodeEditorModule as CkitCodeEditorModule, DateModule as UiKitDateModule,
  InputModule as UiKitInputModule, LayoutModule as UiKitLayoutModule, SelectModule as UiKitSelectModule,
  UploadModule as UiKitUploadModule
} from '@connatix/ui-kit';
import {
  DirectivesModule as CkitDirectivesModule, SelectModule as CkitSelectModule, TableModule as CkitTableModule,
  VideoModule as CkitVideoModule
} from '@connatix/ckit';
import {
  VideoPreviewSliderComponent
} from '@elm/components/library/video-preview-slider/video-preview-slider.component';
import {
  GrabCodeFilteredSliderComponent
} from '@elm/components/library/grab-code-filtered-slider/grab-code-slider.component';
import {
  GrabCodeModule as CkitGrabCodeModule, LibraryMetadataModule as CkitLibraryMetadataModule,
  LibraryModule
} from '@connatix/ckit/library';

@NgModule({
  declarations: [
    VideoPreviewSliderComponent, GrabCodeFilteredSliderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatChipsModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatCheckboxModule,
    UiKitCardsModule,
    UiKitUploadModule,
    UiKitLayoutModule,
    UiKitInputModule,
    UiKitSelectModule,
    UiKitDateModule,
    CkitGrabCodeModule,
    CkitVideoModule,
    CkitTableModule,
    CkitDirectivesModule,
    CkitCodeEditorModule,
    CkitSelectModule,
    CkitLibraryMetadataModule,
    LibraryModule
  ],
  exports: [VideoPreviewSliderComponent, GrabCodeFilteredSliderComponent],
  providers: []
})
export class CnxSharedModule {
}
