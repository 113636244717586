import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {
  BrowserDetectionService, ExternalScriptsService, Globals, PlayerClientScriptService,
  PreviousRouteStateService
} from '@connatix/ckit';
import { Apollo } from 'apollo-angular';
import { DayJsDateAdapterService } from '@connatix/ui-kit';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env/environment';
import { config } from '@env/config';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet ckitFeatureToggleShortcut (activate)="checkUnsupportedBrowsers()"></router-outlet>`
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') themeClass = '';

  constructor(
    private readonly globals: Globals,
    private readonly overlayContainer: OverlayContainer,
    private readonly prevRouteState: PreviousRouteStateService,
    private readonly apollo: Apollo,
    private readonly browserDetectionService: BrowserDetectionService,
    private readonly externalScriptsService: ExternalScriptsService,
    private readonly ds: DayJsDateAdapterService,
    private readonly playerScriptService: PlayerClientScriptService
  ) {
  }

  ngOnInit(): void {
    this.datadogScriptInit();
    this.externalScriptsService.injectHotjar(false);
    this.externalScriptsService.injectResourceHints();
    // init script for elements player
    this.playerScriptService.injectElementsPlayerScript();
    // init date plugins
    this.ds.init('');
  }

  ngAfterViewInit(): void {
    // record navigation
    this.prevRouteState.start();
    this.globals.theme.subscribe(this.setTheme);
  }

  ngOnDestroy(): void {
    // clear cache on destroy
    this.apollo.client.resetStore();
  }

  /** Check unsupported browsers */
  public checkUnsupportedBrowsers(): void {
    this.browserDetectionService.checkUnsupportedBrowsers();
  }

  /**
   *  Set theme if supplied
   * @param theme
   */
  private readonly setTheme = (theme: string): void => {
    if (theme) {
      this.themeClass = theme;
      this.overlayContainer.getContainerElement().classList.add(theme);
    }
  };

  /**
   * Inject datadog script
   */
  private datadogScriptInit(): void {
    /** inject datadog script only in prod */
    if (!environment.development) {
      datadogRum.init(config.datadog);
    }
  }
}
