import { PermissionsEnum } from '@connatix/graphql-layer';
import { MenuItem, MenuSection } from '@connatix/ckit';

export enum MenuItemRequiresEnum {
  ACCOUNT = 'ACCOUNT',
  EXTERNAL = 'EXTERNAL'
}

const VideoPlayer: MenuSection = new MenuSection({
  Name: 'Video Player',
  Items: [
    {
      Name: 'Video Players',
      Route: '/videoplayers',
      Icon: 'ic_players',
      permissions: [PermissionsEnum.VideoPlayersPages]
    }
  ]
});

const MediaSection: MenuSection = new MenuSection({
  Name: 'Media',
  Items: [
    {
      Name: 'Library',
      Route: '/media/library',
      Icon: 'ic_library',
      permissions: [PermissionsEnum.MediaPages]
    },
    {
      Name: 'Playlists',
      Route: '/media/playlists',
      Icon: 'ic_playlists',
      permissions: [PermissionsEnum.PlaylistPages]
    },
    {
      Name: 'Content Suite',
      Route: '/media/marketplace',
      Icon: 'ic_marketplace',
      permissions: [PermissionsEnum.MediaPages]
    }
  ]
});

export const MainMenuSections: MenuSection[] = [
  MediaSection,
  VideoPlayer
];

export const NavigationItems: MenuItem[] = [
  ...MediaSection.Items,
  ...VideoPlayer.Items
];
