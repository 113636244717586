import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  AbstractNavigationService, LayoutService, UserContextService, WordpressService, SideNaveActionsEnum
} from '@connatix/ckit';
import { fadeAnimation } from '@connatix/ui-kit';
import { PermissionsEnum } from '@connatix/graphql-layer';
import { ActivatedRoute, Router } from '@angular/router';
import { GrabCodeHistoryService } from '@connatix/ckit/library';

export class LayoutBreakPoints {
  /** Minimum window width threshold for side bar */
  static readonly sideBarCollapseBreakpoint: number = 1199;
  /** Minimum window width threshold for the mobile menu */
  static readonly mobileMenuBreakpoint: number = 768;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.styles.scss'],
  animations: [
    fadeAnimation
  ]
})
export class LayoutComponent implements OnInit, OnDestroy {
  /** The left sidenav, which contains the main menu */
  @ViewChild('sidenav') sidenav: MatSidenav;

  private subs = [];

  public readonly SideNaveActionsEnum = SideNaveActionsEnum;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly navigation: AbstractNavigationService,
    private readonly router: Router,
    private readonly userContext: UserContextService,
    private readonly wordpressService: WordpressService,
    private readonly historyService: GrabCodeHistoryService,
    private readonly route: ActivatedRoute
  ) {
  }

  private get isMobile(): boolean {
    return this.windowSize.width < LayoutBreakPoints.mobileMenuBreakpoint;
  }

  private get windowSize(): { height: number; width: number } {
    return { height: window.innerHeight, width: window.innerWidth };
  }

  ngOnInit(): void {
    this.initExtension();

    this.subs = [
      this.layoutService.sidenavCollapsed.subscribe(collapsed => {
        // if the sidenav is collapsed, it needs to be open and in side mode
        if (collapsed && this.sidenav) {
          this.sidenav.mode = 'side';
        } else {
          // when the sidenav is not collapsed we need to set the mode based on the window's size
          this.setSidenavMode();
        }
      })
    ];
    this.checkSideNavCollapse();

    // Note: `LayoutComponent`is bound to empty route ( i.e. '' )
    // @see `app-routing.module.ts`
    if (this.navigation.isBaseRoute()) {
      this.navigation.navigate();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s?.unsubscribe());
  }

  public sidenavCloseStart(): void {
    if (this.sidenav) {
      this.sidenav.open();
      this.sidenav.mode = 'side';
      this.layoutService.sidenavCollapsed.next(true);
    }
  }

  public checkSideNavCollapse(): void {
    // if the window's size is smaller than 1199px the sidenav should be collapsed
    const collapsed = this.windowSize.width <= LayoutBreakPoints.sideBarCollapseBreakpoint;
    this.layoutService.sidenavCollapsed.next(collapsed);
  }

  /**
   * HostListener which listens to the page resize
   * and checks if the sidenav need to be collapsed
   */
  @HostListener('window:resize')
  private resize(): void {
    if (!this.isMobile) {
      this.checkSideNavCollapse();
    }
  }

  /** Method which sets the sidenav mode based on the window size */
  private setSidenavMode(): void {
    if (this.sidenav) {
      this.sidenav.mode = 'over';
      if (this.windowSize.width > LayoutBreakPoints.sideBarCollapseBreakpoint) {
        this.sidenav.mode = 'side';
      }
    }
  }

  /*
  * Init extension setup
  * */
  private initExtension(): void {
    const hasLibrary = this.userContext.permissions.some(p => p === PermissionsEnum.MediaPages);
    const hasPlaylists = this.userContext.permissions.some(p => p === PermissionsEnum.PlaylistPages);
    const hasPlayers = this.userContext.permissions.some(p => p === PermissionsEnum.VideoPlayersPages);

    let params = this.route.snapshot.queryParams;
    if (params?.h) {
      // try to parse params to avoid corrupted data or invalid urls
      // set embed history -> last selected player
      try {
        this.historyService.embedCodeHistory = JSON.parse(params.h);
      } catch {
        // if format invalid reset the history property
        this.historyService.embedCodeHistory = {};
        params = {};
        // send the resetted value to the Chrome extension as well
        top.postMessage(this.historyService.embedCodeHistory, '*');
      }
    }

    if (params?.wordpress) {
      this.wordpressService.isWordpress = true;
    }

    if (hasLibrary) {
      this.router.navigate(['/media/library']);
    } else if (hasPlayers) {
      this.router.navigate(['/videoplayers']);
    } else if (hasPlaylists) {
      this.router.navigate(['/media/playlists']);
    } else {
      this.router.navigate(['/denied']);
    }
  }
}
