import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AbstractNavigationService as CkitAbstractNavigationService, AccountInAppGuard,
  AccountsApiService as CkitAccountApiService, AccountsSocialService as CkitAccountsSocialService,
  AdditionalColumnsService as CkitAdditionalColumnsService, CnxUserSidenavService, FeatureToggleModule,
  Globals as CkitGlobals, GraphQLModule as CkitGraphQLModule, GuardsModule as CkitGuardsModule,
  HasAccountGuard as CkitHasAccountGuard, HealthCheckModule, LayoutConfig as CkitLayoutConfig,
  LayoutModule as CkitLayoutModule, LayoutService as CkitLayoutService,
  NotificationsService as CkitNotificationsService, PermissionService as CkitPermissionService, PermissionsGuard,
  PreviousRouteStateService as CkitPreviousRouteStateService
} from '@connatix/ckit';
import { PagesModule as CkitPagesModule } from '@connatix/ckit/pages';
import {
  CONFIG_TOKEN, ENVIRONMENT_TOKEN, LoaderOverlayService as UiKitLoaderOverlayService, MASK_CONFIG,
  PipesModule as UiKitPipesModule, SnackBarModule as UiKitSnackBar, WINDOW_TOKEN
} from '@connatix/ui-kit';
import { provideNgxMask } from 'ngx-mask';
import { BehaviorSubject } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './features/layout/layout/layout.component';
import { MainMenuSections } from './features/layout/sidenav/menu-items/menu.items';
import { SidenavComponent } from './features/layout/sidenav/sidenav.component';
import { AccountLiveStreamGuard } from './guards/account-livestream.guard';
import { AccountOutstreamGuard } from './guards/account-outstream.guard';
import { AccountSaexGuard } from './guards/account-saex.guard';
import { GlobalModule } from './modules/global.module';
import { CnxSharedModule } from './modules/shared.module';
import { NavigationService } from './services/navigation/navigation-service';
import { PERMISSIONS_TOKEN } from '@connatix/graphql-layer';
import { config } from '@env/config';
import { environment } from '@env/environment';
import { ConfigModule } from '@elm/modules/config.module';
import { AuthModule } from '@connatix/ckit/auth';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidenavComponent
  ],
  imports: [
    // angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GlobalModule,
    ConfigModule,
    CkitGuardsModule,
    CkitGraphQLModule,
    CkitPagesModule,
    CkitLayoutModule,
    UiKitSnackBar,
    UiKitPipesModule,
    MatSidenavModule,
    AppRoutingModule,
    MatTooltipModule,
    AuthModule,
    FeatureToggleModule,
    CnxSharedModule,
    HealthCheckModule
  ],
  providers: [
    UiKitLoaderOverlayService,
    CkitGlobals,
    CkitNotificationsService,
    CkitLayoutService,
    CkitPermissionService,
    CkitPreviousRouteStateService,
    CkitAccountApiService,
    CkitAdditionalColumnsService,
    CkitAccountsSocialService,
    CkitHasAccountGuard,
    {
      provide: CkitLayoutConfig,
      useValue: {
        toolsMenuItems: [],
        mainMenuSections: MainMenuSections
      }
    },
    { provide: CkitAbstractNavigationService, useClass: NavigationService },
    { provide: PERMISSIONS_TOKEN, useValue: new BehaviorSubject([]) },
    { provide: WINDOW_TOKEN, useValue: window },
    { provide: CONFIG_TOKEN, useValue: config },
    { provide: ENVIRONMENT_TOKEN, useValue: environment },
    CnxUserSidenavService,
    AccountOutstreamGuard,
    AccountInAppGuard,
    AccountLiveStreamGuard,
    AccountSaexGuard,
    PermissionsGuard,
    provideNgxMask(MASK_CONFIG)
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
