import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export class DragHoverEvent {
  type: 'enter' | 'leave';
  el: Element;
}

@Directive({
  selector: '[appDrop]'
})
export class DropDirective {

  @Output() draggedHover = new EventEmitter<DragHoverEvent>();
  @Output() dropped = new EventEmitter<string>();

  /**
   * HostListener which listens to the dragover event
   * The dragover event is fired when an element or text selection is being dragged over a valid drop target
   */
  @HostListener('dragover', ['$event'])
  private onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  /**
   * HostListener which listens to the drop event
   * The dropped and draggedHover will be triggered when a file is drop
   */
  @HostListener('drop', ['$event'])
  private onDrop(event: DragEvent): void {
    event.preventDefault();

    const data = event.dataTransfer.getData('text');
    this.dropped.emit(data);
    this.draggedHover.emit({ type: 'leave', el: event.target as Element });
  }

  /**
   * HostListener which listens to the dragenter event
   * The draggedHover will be triggered and type will be set as enter
   */
  @HostListener('dragenter', ['$event'])
  private onDragEnter(event: DragEvent): void {
    event.preventDefault();
    this.draggedHover.emit({ type: 'enter', el: event.target as Element });
  }

  /**
   * HostListener which listens to the dragleave event
   * The draggedHover will be triggered and type will be set as leave
   */
  @HostListener('dragleave', ['$event'])
  private onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.draggedHover.emit({ type: 'leave', el: event.target as Element });
  }
}
