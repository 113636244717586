import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractNavigationService, UserContextService } from '@connatix/ckit';
import { NavigationItems } from '../../features/layout/sidenav/menu-items/menu.items';

@Injectable()
export class NavigationService extends AbstractNavigationService {

  constructor(
    router: Router,
    private readonly userContextService: UserContextService
  ) {
    super(router);
  }

  /**
   * Navigate next page
   */
  public navigate(): Promise<boolean> {
    return this.router
      .navigateByUrl(this.getNextPage(), { replaceUrl: true });
  }

  public getUrlPath(): string {
    return '';
  }

  /**
   * Determine next page based on preference and availability
   * 1. Current page
   * 2. Media Library
   * 3. Line Items
   * 4. Any other feature that account has enabled
   * 5. My Profile if account has no features enabled
   */
  private getNextPage(): string {
    const pathname = this.router.url;
    // determine allowed menu items
    const allowedMenuItems = NavigationItems
      //filter hidden item for extension view
      .filter(item => !item.Hidden)
      .filter(
        // item is not permission protected
        item => !item.permissions ||
          // check permissions against user permissions
          item.permissions.every(
            permission => this.userContextService.permissions.includes(permission)
          )
      );
    // identify next page in order if preference
    const nextPage = allowedMenuItems.find(item => pathname.includes(item.Route)) ||
      allowedMenuItems.find(item => item.Route === '/media/library') ||
      allowedMenuItems.find(() => true);
    // return route or denied default fallback )
    return nextPage && nextPage.Route || '/denied';
  }
}
