<!-- SIDEBAR HEADER -->
<div [class.app-switcher-hidden]="isAppSwitcherVisible === false" class="top-container">
  <div class="header-row">
    <button (click)="collapseClicked(!isCollapsed)" class="button-toggle" mat-icon-button>
      <i class="ic_menu"></i>
    </button>
    <div class="logo-wrapper">
      <img alt="platform logo" class="logo" src="/assets/platform-logos/elements.svg">
      <img alt="platform logo" class="logo-small" src="/assets/platform-logos/cnx_small.svg">
    </div>
    <div class="app-switcher-wrapper"></div>
  </div>
</div>
<ckit-feature-toggle *ngIf="featureToggle.experimentsFTVisible" [isCompressed]="isCollapsed"></ckit-feature-toggle>

<!-- SIDEBAR MENU ITEMS -->
<div #sectionsWrapper class="sections-wrapper">
  <div *ngFor="let section of menuSections" class="section-wrapper">
    <div *ngIf="!section.hidden()" class="section">
      <div class="section-header">
        <!-- we will not have rendered at the same time delimiter and section name -->
        <div class="section-divider cnx-delimiter"></div>
        <span class="section-name smallest-2"> {{section.Name | uppercase}} </span>
      </div>
      <div *ngFor="let menuItem of section.Items" class="menu-item-wrapper">
        <div *ngIf="!menuItem.Hidden" [class.menu-item-state-active]="menuItem.Active" class="menu-item">
          <!-- the menu item has no link -->
          <a *ngIf="!menuItem.Link" [routerLink]="[menuItem.Route]">
            <span class="menu-item-icon">
              <i [matTooltipDisabled]="isCollapsed === false" [matTooltip]="menuItem.Name" class="{{menuItem.Icon}}"
                 matTooltipPosition="right"></i>
            </span>
            <span class="menu-item-text smaller-2"> {{menuItem.Name }}</span>
          </a>
          <!-- the menu item has link, open in new tab -->
          <a *ngIf="menuItem.Link" [href]="menuItem.Link" target="_brank">
            <span class="menu-item-icon">
              <i [matTooltipDisabled]="isCollapsed === false" [matTooltip]="menuItem.Name" class="{{menuItem.Icon}}"
                 matTooltipPosition="right"></i>
            </span>
            <span class="menu-item-text smaller-2"> {{menuItem.Name }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>