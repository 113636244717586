<div class="wrapper">
  <a (blur)="toggleShow()" (focus)="toggleShow()" [ngClass]="{'outline': appearance === 'outline'}"
     [ngStyle]="{background: isValidHex(value) ? value : initialColor}"
     [tabIndex]="1"
     class="color-preview"
     mat-flat-button routerLink=".">
  </a>
  <div [ngClass]="{'hidden': !show}" class="colorpicker-container">
    <div #cont class="picker-cont"></div>
    <button (click)="toggleShow()" mat-flat-button>CHOOSE COLOR</button>
  </div>
  <mat-form-field class="colorpicker-form">
    <mat-label>
      {{placeholder}}
    </mat-label>
    <label>
      <input [(ngModel)]="value" [disabled]="isDisabled" autocomplete="off" matInput maxlength="7">
    </label>
  </mat-form-field>
</div>