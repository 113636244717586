<ckit-authenticated-hc></ckit-authenticated-hc>
<mat-sidenav-container autosize>
  <!-- Menu sidenav -->
  <mat-sidenav #sidenav (closedStart)="sidenavCloseStart()" mode="side" opened="true" position="start">
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <section class="page-header-placeholder">
    <app-cnx-user-sidenav
      [hiddenActions]="[SideNaveActionsEnum.IMPERSONATE, SideNaveActionsEnum.MY_PROFILE,
      SideNaveActionsEnum.SETTINGS, SideNaveActionsEnum.CHANGE_PASSWORD]">
    </app-cnx-user-sidenav>
  </section>
  <!-- Main content -->
  <main [@fadeAnimation]="o.isActivated ? o?.activatedRoute : false">
    <router-outlet #o="outlet"></router-outlet>
  </main>
</mat-sidenav-container>
