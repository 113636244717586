import { NgModule, Type } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard, PermissionsGuard } from '@connatix/ckit';
import { LayoutComponent } from './features/layout/layout/layout.component';
import { MediaModule } from './features/media/media.module';
import { PermissionsEnum } from '@connatix/graphql-layer';
import { AccessDeniedComponent, NotFoundPageComponent } from '@connatix/ckit/pages';
import { PlaylistsModule } from '@elm/features/media/playlists/playlists.module';
import { LoginComponent } from '@connatix/ckit/auth';
import { VideoPlayersModule } from '@elm/features/video-players/video-players.module';

const routes: Routes = [
  // authentication
  { path: 'login', component: LoginComponent, data: { hideFooter: 'true' } },
  {
    path: '', component: LayoutComponent, canActivate: [LoggedInGuard], children: [
      // Media
      {
        path: 'media',
        loadChildren: (): Promise<Type<MediaModule>> => import('./features/media/media.module')
          .then(m => m.MediaModule),
        data: {
          permissions: [PermissionsEnum.MediaPages]
        },
        canActivate: [PermissionsGuard]
      },
      // Playlist
      {
        path: 'media/playlists',
        loadChildren: (): Promise<Type<PlaylistsModule>> => import('./features/media/playlists/playlists.module')
          .then(m => m.PlaylistsModule),
        data: {
          permissions: [PermissionsEnum.PlaylistPages]
        },
        canActivate: [PermissionsGuard]
      },
      // Video Player
      {
        path: 'videoplayers',
        loadChildren: (): Promise<Type<VideoPlayersModule>> => import('./features/video-players/video-players.module').then(m => m.VideoPlayersModule),
        data: {
          permissions: [PermissionsEnum.VideoPlayersPages]
        },
        canActivate: [PermissionsGuard]
      },
      {
        path: 'denied', component: AccessDeniedComponent
      }
    ]
  },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
