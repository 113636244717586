import {
  Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[appDrag]'
})
export class DragDirective implements OnChanges {

  @Input() dragData: string = null;
  @Input() dragDisabled = false;

  @Output() dragStarted = new EventEmitter();
  @Output() dragEnded = new EventEmitter();

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {
    el.nativeElement.draggable = !this.dragDisabled;
  }

  @HostListener('dragstart', ['$event'])
  private onDragStart(event: DragEvent): void {
    // set event data transfer
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text', this.dragData);

    // executes after bitmap for dragged element is generated
    setTimeout(() => {
      // hide element and stop any mouse action on element
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
    }, 0);

    // emit event
    this.dragStarted.emit();
  }

  @HostListener('dragend')
  private onDragEnd(): void {
    // reset element as it was before dragging
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'all');

    // emit
    this.dragEnded.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dragDisabled']) {
      this.el.nativeElement.draggable = !this.dragDisabled;
    }
  }
}
