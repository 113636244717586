import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDirective } from './drag-and-drop/drag.directive';
import { DropDirective } from './drag-and-drop/drop.directive';
import { DirectivesModule as CkitDirectivesModule } from '@connatix/ckit';

// core directives
const directives = [
  DragDirective,
  DropDirective
];

@NgModule({
  declarations: [...directives],
  // core imports
  imports: [
    CommonModule,
    CkitDirectivesModule
  ],
  // core exports
  exports: [...directives, CkitDirectivesModule]
})
export class CoreDirectivesModule { }
