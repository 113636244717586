import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsEnum } from '@connatix/graphql-layer';
import { UserContextService } from '@connatix/ckit';

/**
 * Guard that verifies if a user can access a page or not, based on the user - route permissions
 */
@Injectable()
export class AccountSaexGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly userContextService: UserContextService
  ) {
  }

  public canActivate(): Observable<boolean> {
    return this.userContextService.account$
      .pipe(
        map(() => {
          const hasManageSaexPermission = this.userContextService.permissions.includes(PermissionsEnum.ManageSaexPlayers);
          const allowed = (this.userContextService.isAllAccounts()
              && hasManageSaexPermission)
            || (this.userContextService.account.saexPlayersEnabled && hasManageSaexPermission);
          if (!allowed) {
            this.router.navigate(['/denied']);
          }
          return allowed;
        })
      );
  }
}
