<ui-kit-side-drawer-slider ckitEscapeKey (escapeKeyPress)="close()"
                           [pageTitle]="isDetailsView ? 'Customize Embedded Video Instance' : 'Video Details Preview'"
                           [pageSubtitle]="data.title" (pageCloseAction)="close()">
  <div page>
    <div class="simple-widget">
      <div class="simple-widget-content" *ngIf="showSkeletonLoader">
        <ckit-side-drawer-loader></ckit-side-drawer-loader>
      </div>
      <div class="simple-widget-content" [hidden]="showSkeletonLoader">
        <div class="row ">
          <div class="col-xs-12">

            @if (data.useVideoTagPreview) {
              <ckit-video-preview *ngIf="data.useVideoTagPreview" [video]="videoModel" [showTracks]="true"
                                  [isElementsLibrary]="data.isElementsLibrary">
                <div video-preview-extra-metadata *ngIf="data.extraMetadata">
                  <ckit-video-extra-metadata [metadata]="data.extraMetadata"></ckit-video-extra-metadata>
                </div>
                <ui-kit-info-box *ngIf="isSyndicationVideo() && data.syndicationTier" video-preview-extra-metadata>
                  This is <span class="highlight-default">{{ data.syndicationTier | titleCaseEnum }}</span>
                  video content and has the
                  <span class="highlight-default">{{ tierConfig.get(data.syndicationTier) }}</span>
                  play rate. Desktop play costs more than mobile play.
                </ui-kit-info-box>
              </ckit-video-preview>
            } @else {
              <ckit-cnx-video-preview *ngIf="!data.useVideoTagPreview" [video]="videoModel"
                                      [isElementsLibrary]="data.isElementsLibrary">
                <div video-preview-extra-metadata *ngIf="data.extraMetadata">
                  <ckit-video-extra-metadata [metadata]="data.extraMetadata"></ckit-video-extra-metadata>
                </div>
                <ui-kit-info-box *ngIf="isSyndicationVideo() && data.syndicationTier" video-preview-extra-metadata>
                  This is <span class="highlight-default">{{ data.syndicationTier | titleCaseEnum }}</span>
                  video content and has the
                  <span class="highlight-default">{{ tierConfig.get(data.syndicationTier) }}</span>
                  play rate. Desktop play costs more than mobile play.
                </ui-kit-info-box>
              </ckit-cnx-video-preview>
            }
          </div>
        </div>
        <ng-container *ngIf="data.displayEmbedCode">
          <ui-kit-block-subtitle subtitle="Embed Code"></ui-kit-block-subtitle>
          <div class="row">
            <ckit-select-player-by-type
                class="col-xs-9" *ngIf="allowPlayers"
                [playerSelectedInnerValue]="playerSelected" [disableInApp]="true"
                (playerSelected)="handleSelectedPlayer($event)">
            </ckit-select-player-by-type>
          </div>
          <div class="row" *ngIf="playerSelected.key === playerMediumEnum.WEB">
            <ckit-grab-code class="col-xs" [embedCodeModel]="embedCodeModel" [embedCodeType]="mode"
                            (embedCodeTypeChange)="handleCodeTypeChange($event)">
            </ckit-grab-code>
          </div>
          <div class="row" *ngIf="playerSelected.key === playerMediumEnum.APP">
            <ckit-grab-code-inapp class="col-xs" [embedCodeModel]="embedCodeInApp" [embedCodeType]="mode"
                                  [embedCodeApps]="apps" (embedCodeTypeChange)="handleCodeSubTypeChange($event)"
                                  (embedCodeAppSelected)="handleAppSelected($event)">
            </ckit-grab-code-inapp>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div page-actions class="slider-footer">
    <button mat-stroked-button color="primary" [disabled]="!historyService.bodyScript"
            (click)="slideDrawer.slideToDetailsPage(); applyExtraData = false; isDetailsView = true"
            *ngIf="showSideDrawerDetails && playerSelected.key !== playerMediumEnum.APP">
      Customize Embedded Instance
    </button>
    <button mat-flat-button color="primary" *ngIf="showSendEmbedCodetoCMS()"
            [disabled]="!historyService.bodyScript"
            (click)="historyService.sendEmbedCodetoCMS(historyService.bodyScript, extensionMessageData)">
      Send Embed Code to CMS
    </button>
    <button mat-flat-button color="primary" *ngIf="wordpressService.isWordpress"
            (click)="historyService.sendScriptToWordpress()" [disabled]="!historyService.bodyScript">
      Insert Code Into Article
    </button>
  </div>

  <div class="content-wrapper" details>
    <div class="simple-widget">
      <div class="simple-widget-content">
        <ckit-video-options [settingsForm]="extraDataForm"></ckit-video-options>
      </div>
    </div>
  </div>

  <div details-actions *ngIf="showSideDrawerDetails">
    <button mat-stroked-button color="primary"
            (click)="slideDrawer.slideToHomePage(); isDetailsView = false;">
      Cancel
    </button>
    <button mat-flat-button color="primary" (click)="applyChanges()">
      Apply Changes
    </button>
  </div>

</ui-kit-side-drawer-slider>