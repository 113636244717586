import { Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldAppearance, MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import { isValidHex } from '@connatix/ui-kit';

import { noop } from 'rxjs';
import { ColorPickerInputFactory, IroColorPicker } from './color-picker-input.factory';

@Component({
  selector: 'app-color-picker-input',
  templateUrl: './color-picker-input.component.html',
  styleUrls: ['./color-picker-input.component.styles.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerInputComponent),
    multi: true
  }]
})
export class ColorPickerInputComponent implements OnInit, ControlValueAccessor {
  /** the input placeholder */
  @Input() placeholder: string;

  /** if the input should be disabled */
  @Input() isDisabled = false;

  /** the color picker container */
  @ViewChild('cont', { read: ElementRef, static: true }) cont: ElementRef;

  /** show/hide variable used by the color picker */
  public show = false;

  public appearance?: MatFormFieldAppearance;

  /** Hex code validator; used in html */
  public readonly isValidHex = isValidHex;

  /** Keep the initial color */
  public initialColor: string;
  /** color picker instance */
  private colorPickerInstance: IroColorPicker;
  /** Callback used to notify FORM if value changes */
  private onChange: (_) => void = noop;
  /** Callback used to notify FORM if any blur/focus event */
  private onTouched: () => void = noop;

  constructor(
    @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) { appearance }: MatFormFieldDefaultOptions,
    private readonly colorPickerInputFactory: ColorPickerInputFactory
  ) {
    this.appearance = appearance;
  }

  /** internal data model */
  private _value: string = null;

  public get value(): string {
    return this._value;
  }

  public set value(hex) {
    this._value = hex;
    this.onChange(hex);
    this.onTouched();

    if (isValidHex(hex)) {
      this.colorPickerInstance.color.hexString = hex;
    }
  }

  ngOnInit(): void {
    /** color picker config */
    const config = {
      width: 200,
      height: 200,
      sliderMargin: 20,
      sliderHeight: 20,
      color: isValidHex(this._value) ? this._value : this.initialColor
    };

    this.colorPickerInstance = this.colorPickerInputFactory.createIroColorPicker(this.cont.nativeElement, config);
    this.colorPickerInstance.on('color:change', color => {
      this._value = color.hexString;
      this.onChange(color.hexString);
      this.onTouched();
    });
  }

  /** Show/Hide the Color Picker Html Element */
  public toggleShow(): void {
    this.show = !this.show && !this.isDisabled;
  }

  public registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(hex: string): void {
    this._value = hex;
    this.initialColor = hex;
  }
}
