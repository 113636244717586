import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  DialogModule as UiKitDialogModule, InputModule as UiKitInputModule, SelectModule as UiKitSelectModule
} from '@connatix/ui-kit';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskDirective } from 'ngx-mask';
import { CnxMaterialModule } from '../../../modules/material.module';
import { ColorPickerInputComponent } from './color-picker-input/color-picker-input.component';
import { InputValidationComponent } from './input-validation/input-validation.component';

/** form modules list */
const modules = [
  // angular modules
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  InfiniteScrollModule,
  NgxMaskDirective,
  // ui-kit modules
  UiKitInputModule,
  UiKitSelectModule,
  UiKitDialogModule,
  // elements modules
  CnxMaterialModule
];
/** form declarations list */
const declarations = [
  InputValidationComponent,
  ColorPickerInputComponent
];
const providers = [
  // mat-form-fields settings
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'fill'
    }
  }
];

@NgModule({
  imports: [
    ...modules
  ],
  declarations: [...declarations],
  exports: [...declarations, UiKitSelectModule],
  providers: [...providers]
})
export class FormControlsModule {
}
