import { Injectable } from '@angular/core';

import iro from '@jaames/iro';

// check https://www.npmjs.com/package/@jaames/iro for more options
export interface IroConfig {
  width: number;
  height: number;
  sliderMargin: number;
  sliderHeight: number;
  color: string;
}

export interface IroColorPicker {
  color: {
    hexString: string;
  };

  on(event: string, callback: (color: { hexString: string }) => void): void;

  off(event: string, callback: (color: { hexString: string }) => void): void;
}

@Injectable({
  providedIn: 'root'
})
export class ColorPickerInputFactory {

  /**
   * Create IroColorPicker
   * @param nativeElement - color picker native element
   * @param config - contains a set of properties: width, height, sliderMargin, sliderHeight and color
   * @param - returns color picker
   */
  public createIroColorPicker(nativeElement: HTMLElement, config: IroConfig): IroColorPicker {
    return iro.ColorPicker(nativeElement, config);
  }
}
