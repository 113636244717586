import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.styles.scss']
})
export class InputValidationComponent implements OnInit {

  /** Form control instance */
  @Input() control: FormControl;

  /** General error */
  @Input() isGeneralError = false;

  /** Ignore pristine check on displaying errors */
  @Input() ignorePristine = false;

  /** Errors to display */
  public errors: string[];

  ngOnInit(): void {
    this.control.statusChanges.subscribe(() => {
      this.parseErrors();
    });

    this.parseErrors();
  }

  /** checks if error should be displayed */
  public shouldDisplay(): boolean {
    if (this.isGeneralError) {
      return !this.control.valid;
    }

    return (this.control.touched || this.control.dirty) && this.control.invalid;
  }

  /** parse errors for control */
  private parseErrors(): void {
    if (!this.control.valid && this.control.errors) {
      Object.keys(this.control.errors).forEach(keyError => {
        this.errors = this.buildMessage(keyError, this.control.errors[keyError]);
      });
    } else {
      this.errors = [];
    }
  }

  /**
   *
   *
   * Method that returns a custom error message based on the errorType that it is provided
   *
   * @param errorType - the validation type that failed eg: required, email, someCustomValidation
   * @param errorValue - the value of the validation that failed
   * @returns string - the error message of the errorType provided
   */
  private buildMessage(errorType: string, errorValue: ValidationErrors): string[] {
    let error: string[];

    switch (errorType) {
      case 'required':
        error = ['This field is required'];
        break;
      case 'invalid_integer':
        error = ['Not a valid number'];
        break;
      case 'min':
        error = [`Minimum value ${errorValue.min}`];
        break;
      case 'max':
        error = [`Maximum value ${errorValue.max}`];
        break;
      case 'maxlength':
        error = [`Maximum length ${errorValue.requiredLength}`];
        break;
      case 'custom':
        error = errorValue as string[];
        break;
      case 'invalid_decimal':
        error = ['Not a valid decimal number'];
        break;
      case 'invalid_url':
        error = [`Not a valid URL`];
        break;
      default:
        error = ['This field is invalid'];
    }

    return error;
  }
}
