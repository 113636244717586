// This file can be replaced during build by using the `fileReplacements` array.
// `ng build -c {env}` replaces `environment.ts` with `environment.{env}.ts`.
// The list of file replacements can be found in `angular.json`.

export const config = {
  version: '1.0.0.0',
  dynamicUrls: {
    consoleAPI: 'conapi',
    elements: 'embedded',
    hub: 'embedded',
    auth: 'auth'
  },
  staticUrls: {
    images: 'https://img.connatix.com/',
    videos: 'https://vid.connatix.com/'
  },
  aws: {
    tempBucket: 'cnx-temp-uploads',
    uploaderKeyProd: 'AKIAS2KXEIVJCUPKKMXZ',
    uploaderKeyDev: 'AKIAS2KXEIVJNFHNXRHA'
  },
  facebook: {
    url: 'https://connect.facebook.net/en_US/sdk.js',
    appId: 516857439059409
  },
  hotjar: {
    id: 5136104
  },
  datadog: {
    applicationId: 'e0e0fe39-c7e4-4769-b4f4-d724a621c4aa',
    clientToken: 'puba8aa51ea4c8d488bdaaca6a94e80f9d3',
    site: 'datadoghq.com',
    service: 'ui-content-management-extension',
    // version: '1.0.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: false
  },
  /** google client_id */
  googleAuth: '396601995696-dauvavghn0m4n312tmfvq1fjrfelq4gq.apps.googleusercontent.com',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
