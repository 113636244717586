<ui-kit-side-drawer-slider ckitEscapeKey (escapeKeyPress)="close()"
                           [pageTitle]="isDetailsView ? detailedViewTitle : title" [pageSubtitle]="subtitle" (pageCloseAction)="close()">
  <div page class="slider-content">
    <div class="simple-widget">
      <div class="simple-widget-content" *ngIf="showSkeletonLoader">
        <ckit-side-drawer-loader></ckit-side-drawer-loader>
      </div>
      <div class="simple-widget-content" [hidden]="showSkeletonLoader">
        <div class="row">
          <ckit-select-player-by-type
              class="col-xs-9" *ngIf="allowPlayers" [playerSelectedInnerValue]="playerSelected"
              [disableInApp]="true" (playerSelected)="handleSelectedPlayer($event)">
          </ckit-select-player-by-type>
        </div>
        <ng-container *ngIf="allowLeadMedia">
          <div class="form-field">
            <div class="form-field-wrapper">
              <div class="row ">
                <div class="col-xs-9">
                  <ui-kit-info-box>{{leadVideoHint}}
                  </ui-kit-info-box>
                </div>
              </div>
              <div class="row">
                <ckit-select-media class="col-xs-9" placeholder="Add Primary Lead Video (optional)"
                                   [(ngModel)]="leadVideoIds[0]" [accountId]="accountId" (mediaChange)="loadEmbedCode(playerSelected)">
                </ckit-select-media>
              </div>
              <div class="row">
                <ckit-select-media class="col-xs-9" placeholder="Add Secondary Lead Video (optional)"
                                   [(ngModel)]="leadVideoIds[1]" [disabled]="!leadVideoIds[0]" [accountId]="accountId"
                                   (mediaChange)="loadEmbedCode(playerSelected)">
                </ckit-select-media>
              </div>
            </div>
            <div class="form-field-error">
              <div class="error" *ngIf="isDuplicatedLeadVideos()">Duplicated Lead Videos</div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isSaexPlayer">
          <div class="row">
            <ckit-grab-code-saex class="col-xs"
                                 [embedCodeModel]="embedCodeSaexPlayer.embedCodeTag">
            </ckit-grab-code-saex>
          </div>
        </ng-container>

        <ng-container *ngIf="!isSaexPlayer">
          <div class="row" *ngIf="playerSelected.key === playerMediumEnum.WEB">
            <ckit-grab-code class="col-xs" [embedCodeModel]="embedCodeModel" [embedCodeType]="mode"
                            (embedCodeTypeChange)="handleCodeTypeChange($event)">
            </ckit-grab-code>
          </div>
          <div class="row" *ngIf="playerSelected.key === playerMediumEnum.APP">
            <ckit-grab-code-inapp class="col-xs" [embedCodeModel]="embedCodeInApp" [embedCodeType]="mode"
                                  [embedCodeApps]="apps" (embedCodeTypeChange)="handleCodeSubTypeChange($event)"
                                  (embedCodeAppSelected)="handleAppSelected($event)">
            </ckit-grab-code-inapp>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="slider-content" details>
    <div class="simple-widget">
      <div class="simple-widget-content">
        <ckit-video-options *ngIf="isLibraryPage" [settingsForm]="extraDataForm"></ckit-video-options>
        <ckit-player-options *ngIf="isPlayersPage" [settingsForm]="extraDataForm" [accountId]="playerAccountId">
        </ckit-player-options>
      </div>
    </div>
  </div>

  <div page-actions>
    <div class="col-xs-12 action-buttons">
      <button mat-stroked-button color="primary" [disabled]="!historyService.bodyScript"
              (click)="slideDrawer.slideToDetailsPage(); applyExtraData = false; isDetailsView = true"
              *ngIf="showSideDrawerDetails && playerSelected.key !== playerMediumEnum.APP">
        Customize Embedded Instance
      </button>
      <button mat-flat-button color="primary" *ngIf="showSendEmbedCodetoCMS()"
              [disabled]="!historyService.bodyScript"
              (click)="historyService.sendEmbedCodetoCMS(historyService.bodyScript, extensionMessageData, isLibraryPage)">
        Send Embed Code to CMS
      </button>
      <button mat-flat-button color="primary" *ngIf="wordpressService.isWordpress"
              (click)="historyService.sendScriptToWordpress()" [disabled]="!historyService.bodyScript">
        Insert Code Into Article
      </button>
    </div>
  </div>

  <div details-actions *ngIf="showSideDrawerDetails && playerSelected.key !== playerMediumEnum.APP">
    <button mat-stroked-button color="primary"
            (click)="slideDrawer.slideToHomePage(); isDetailsView = false">
      Cancel
    </button>
    <button mat-flat-button color="primary" (click)="applyChanges()">
      Apply Changes
    </button>
  </div>

</ui-kit-side-drawer-slider>