// Angular modules
import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DAYJS_DATE_FORMATS, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@connatix/ui-kit';

const MATERIAL_MODULES = [
  // angular modules
  ScrollingModule,
  OverlayModule,
  PortalModule,

  // angular material modules
  MatInputModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatIconModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatRadioModule,
  MatTabsModule,
  MatButtonModule,
  MatSelectModule,
  MatDialogModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatSliderModule,
  MatStepperModule,
  MatMenuModule,
  MatSortModule,
  MatSidenavModule,
  MatListModule,
  MatDatepickerModule,
  MatTreeModule,
  MatBadgeModule,
  MatExpansionModule,
  MatButtonToggleModule
];

@NgModule({
  imports: [...MATERIAL_MODULES, CdkTableModule],
  exports: [...MATERIAL_MODULES, CdkTableModule],
  providers: [
    {
      provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true
      }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DAYJS_DATE_FORMATS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-US'
    }
  ]
})
export class CnxMaterialModule { }
